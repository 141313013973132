declare const $: any;

import ComponentSettings from './ComponentSettings';
import Tabs from '../Interfaces/Tabs';

export default class RadioSettings extends ComponentSettings {
    private component: any;
    private tabs: Array<Tabs> = [];

    constructor(section) {
        super(section);
        this.component = section.component;
        this.buildTemplate();
    }

    private buildTemplate() {

        /* Main fieldset
        -------------------------------------------------- */

        const $mainTab: Array<JQuery> = [];

        // Inline
        const selectData = {
            data: {
                prop: 'inline'
            },
            name: 'inline',
            label: 'Inline',
            tooltip: 'Display the radio buttons inline or stacked',
            value: this.component.inline
        }
        const selectOptions: any = [
            {
                'Yes': 'true'
            },
            {
                'No': 'false'
            }
        ];
        const $inlineData = this.addSelect(selectData, selectOptions);

        $mainTab.push($inlineData);

        // center
        const selectCenterData = {
            data: {
                prop: 'center'
            },
            name: 'center',
            label: 'Center',
            tooltip: 'Center the radio group inside its container.',
            value: this.component.center
        }
        const selectCenterOptions: any = [
            {
                'Yes': 'true'
            },
            {
                'No': 'false'
            }
        ];
        const $centerData = this.addSelect(selectCenterData, selectCenterOptions);

        $mainTab.push($centerData);

        // Name
        const nameData = {
            data: {
                prop: 'name'
            },
            label: 'Radiogroup name',
            name: 'name',
            value: this.component.name,
            pattern: '[a-z0-9_\\[\\]-]+',
            tooltip: 'alphanumeric characters, hyphens, underscores and hooks only.'
        }
        const $inputName = this.addInput(nameData);
        $mainTab.push($inputName);

        // Value
        const $selectValue = this.buildSelectDefaultValue(this.component.value, this.component.radioButtons);
        $mainTab.push($selectValue);

        // Label
        const labelData = {
            data: {
                prop: 'label'
            },
            label: 'Radiogroup label',
            name: 'label',
            value: this.component.label
        }
        const $inputLabel = this.addInput(labelData);
        $mainTab.push($inputLabel);

        // HelpText
        const helperData = {
            data: {
                prop: 'helper'
            },
            label: 'Helper',
            name: 'helper',
            tooltip: 'Helper text displayed below the field.',
            value: this.component.helper
        }
        const $inputHelper = this.addInput(helperData);
        $mainTab.push($inputHelper);

        // Class
        const clazzData = {
            data: {
                prop: 'clazz'
            },
            label: 'Radiogroup Class',
            name: 'clazz',
            value: this.component.clazz
        }
        const $inputClazz = this.addInput(clazzData);
        $mainTab.push($inputClazz);

        // width
        const isFormHorizontal = $('#fg-form-wrapper form').hasClass('form-horizontal');
        const selectWidthData = {
            data: {
                prop: 'width'
            },
            disabled: !isFormHorizontal,
            name: 'input-width-data',
            tooltip: 'The width can be set only in horizontal forms. It has no effect on others layouts.',
            label: 'Width',
            value: '100%'
        }
        const selectWidthOptions: Array<string> = ['33%', '50%', '66%', '100%'];
        const $selectWidth = this.addSelect(selectWidthData, selectWidthOptions);

        $mainTab.push($selectWidth);

        // Attr
        const checkData = {
            checkboxes: [{
                checked: this.component.attr.find(a => a.name === 'required') !== undefined,
                data: {
                    prop: 'required'
                },
                label: 'Required',
                name: 'required',
                value: 'required'
            }],
            label: '',
            plugin: 'niceCheck'
        }
        const $checkMisc = this.addCheckboxes(checkData);
        $mainTab.push($checkMisc);

        this.tabs.push({
            title: 'Main',
            id: 'tab-main',
            content: $mainTab
        });

        /* radioButtons
        -------------------------------------------------- */

        const $radioButtonsTab: Array<JQuery> = [];

        const $toggleCollapse = $(`<div class="d-flex justify-content-between my-1">
            <button type="button" class="${this.ui.topButtonsClass} collapse-all-btn" role="button"><small>Collapse <i class="icon-shrink text-white ms-1"></i></small></button>
            <button type="button" class="${this.ui.topButtonsClass} expand-all-btn" role="button"><small>Expand <i class="icon-expand text-white ms-1"></i></small></button></div>`);

        $radioButtonsTab.push($toggleCollapse);

        const $itemsWrapper = $('<div />').attr('id', 'items-wrapper');

        this.component.radioButtons.forEach(radioBtn => {
            const $fieldset = this.buildRadioButton(radioBtn);
            $itemsWrapper.append($fieldset);
        });

        $radioButtonsTab.push($itemsWrapper);

        const $addRadioButtonBtn = $(`<div class="d-flex justify-content-end my-1"><button type="button" class="${this.ui.addButtonsClass} add-btn" role="button"><small>Add new <i class="icon-plus-circle text-white ms-1"></i></small></button></div>`);

        $radioButtonsTab.push($addRadioButtonBtn);
        this.tabs.push({
            title: 'Buttons',
            id: 'tab-items',
            content: $radioButtonsTab
        });

        /* Plugins
        -------------------------------------------------- */

        const $pluginsTab: Array<JQuery> = [];

        const $helper = $('<div />').addClass('pt-4').html(this.helper('The Nicecheck, Pretty Checkbox and iCheck plugins are available globally in the Main settings.<br><br>If you enable the Pretty Checkbox plugin some additional specific settings will be available for each radio button in the <em>Buttons</em> tab.'));
        $pluginsTab.push($helper);

        this.tabs.push({
            title: 'Plugins',
            id: 'tab-plugins',
            content: $pluginsTab
        });

        this.renderSection(this.tabs, () => {
            this.component.radioButtons.forEach(rad => {
                // set delete btn popover
                const popoverDeleteData = {
                    sectionId: this.section.id,
                    componentType: 'radio',
                    itemIndex: rad.index
                };
                this.enableDeletePopover(popoverDeleteData, `#tab-items fieldset[data-index="${rad.index}"]`);
            });
        });
    }

    private buildRadioButton(rad) {
        const $fieldset = $('<fieldset />').addClass(this.ui.fieldsetClass).attr('data-index', rad.index);
        const legendHtml = `<button class="${this.ui.collapsibleButtonsClass}" type="button" data-bs-toggle="collapse" data-bs-target="#collapsible-radio-${rad.index}" aria-expanded="true" aria-controls="collapsible-radio-${rad.index}">radio ${rad.index}</button>`;
        const $legend = $('<legend />').html(legendHtml).addClass(this.ui.legendClass);
        $fieldset.append($legend);

        const $btnDelete = `<button type="button" class="btn btn-link text-decoration-none btn-delete" data-bs-toggle="popover"><i class="icon-delete text-danger"></i></button>`;
        $fieldset.append($btnDelete);

        const $collapsible = $('<div />').addClass('collapse show').prop('id', `collapsible-radio-${rad.index}`);

        // Text
        const textData = {
            data: {
                index: rad.index,
                prop: 'text'
            },
            label: 'Text',
            name: 'radio-' + rad.index,
            value: rad.text
        }

        const $inputText = this.addInput(textData);
        $collapsible.append($inputText);

        // Value
        const valueData = {
            data: {
                index: rad.index,
                prop: 'value'
            },
            label: 'Value',
            name: 'radio-value' + rad.index,
            value: rad.value
        }
        const $inputValue = this.addInput(valueData);
        $collapsible.append($inputValue);

        // Disabled
        const checkData = {
            checkboxes: [{
                checked: 'checked',
                data: {
                    index: rad.index,
                    prop: 'disabled'
                },
                label: 'Disabled',
                name: 'radio-disabled' + rad.index,
                value: true
            }],
            label: '',
            plugin: 'niceCheck'
        }
        const $checkData = this.addCheckboxes(checkData);
        $collapsible.append($checkData);

        /* Pretty checkbox data-attrs
        -------------------------------------------------- */

        let activeClass = '';
        if (this.isPrettyCheckboxEnabled()) {
            activeClass = ' active';
        }
        const $prettyCheckboxFieldset = $('<fieldset />').addClass('pretty-checkbox-fieldset p-2 mx-n2 mb-n2 mt-4 border-top bg-dark bg-opacity-10' + activeClass);
        const $doc = this.addDocumentation('https://www.phpformbuilder.pro/documentation/javascript-plugins.php#pretty-checkbox-example');
        const $prettyCheckboxLegend = $('<legend />').html('Pretty checkbox plugin settings').append($doc).addClass('bg-dark bg-opacity-10 d-inline-flex align-items-center w-auto px-2 py-1 mb-n2 border fw-light h6');
        $prettyCheckboxFieldset.append($prettyCheckboxLegend);

        // color
        const selectColorData = {
            data: {
                index: rad.index,
                prop: 'data-color'
            },
            name: 'color-data' + rad.index,
            label: 'Color',
            helper: 'Choose an outline color if you set an icon and want to remove the borders.',
            value: this.getAttributeValue(rad.attr, 'data-color')
        }
        const selectOptions: any = [
            {
                'Default': ''
            },
            {
                'primary': 'primary'
            },
            {
                'Primary outline': 'primary-o'
            },
            {
                'Success': 'success'
            },
            {
                'Success outline': 'success-o'
            },
            {
                'Info': 'info'
            },
            {
                'Info outline': 'info-o'
            },
            {
                'Warning': 'warning'
            },
            {
                'Warning outline': 'warning-o'
            },
            {
                'Danger': 'danger'
            },
            {
                'Danger outline': 'danger-o'
            }
        ];
        const $selectColor = this.addSelect(selectColorData, selectOptions);

        $prettyCheckboxFieldset.append($selectColor);

        // icon
        const iconData = {
            data: {
                index: rad.index,
                prop: 'data-icon',
                uniqid: this.makeUniqId(5),
                iconset: $('body').attr('data-icon-font'),
                icon: rad.attr.find(attr => attr.name === 'data-icon').value
            },
            name: 'icon' + rad.index,
            tooltip: 'Go to <em>Main settings</em> => <em>Form Plugins</em> if you want to remove the radio buttons borders',
            helper: '<em>Check</em> icon if <em>Toggle</em> is Off.',
            label: 'Icon'
        }
        const $inputIcon = this.addIconPicker(iconData);
        $prettyCheckboxFieldset.append($inputIcon);

        // toggle
        const toggleData = {
            checkboxes: [{
                checked: this.getAttributeValue(rad.attr, 'data-toggle', true),
                data: {
                    index: rad.index,
                    prop: 'data-toggle'
                },
                name: 'toggle-data' + rad.index,
                label: '',
                value: true
            }],
            label: 'Toggle',
            tooltip: 'Toggle allow to show different content depending on the input on / off state.',
            plugin: 'lcswitch',
            dataontext: 'On',
            dataofftext: 'Off'
        }
        const $checkToggle = this.addCheckboxes(toggleData);
        $prettyCheckboxFieldset.append($checkToggle);

        const $toggleHelper = $('<p class="small mt-3 py-1 ps-4 pr-2 mb-0 bg-white bg-opacity-50"><i class="icon-lifebuoy ms-n3 me-1"></i>The following settings have no effect if <em>Toggle</em> is <em>Off</em>.</p>');
        $prettyCheckboxFieldset.append($toggleHelper);

        // on-label
        const onLabelData = {
            data: {
                index: rad.index,
                prop: 'data-on-label'
            },
            name: 'on-label-data' + rad.index,
            label: '<em>On</em> label',
            tooltip: 'The text of the label when checked',
            value: this.getAttributeValue(rad.attr, 'data-on-label')
        }
        const $inputOnLabel = this.addInput(onLabelData);

        $prettyCheckboxFieldset.append($inputOnLabel);

        // off-label
        const offLabelData = {
            data: {
                index: rad.index,
                prop: 'data-off-label'
            },
            name: 'off-label-data' + rad.index,
            label: '<em>Off</em> label',
            tooltip: 'The text of the label when unchecked',
            value: this.getAttributeValue(rad.attr, 'data-off-label')
        }
        const $inputOffLabel = this.addInput(offLabelData);

        $prettyCheckboxFieldset.append($inputOffLabel);

        // on-icon
        const onIconData = {
            data: {
                index: rad.index,
                prop: 'data-on-icon',
                uniqid: this.makeUniqId(5),
                iconset: $('body').attr('data-icon-font'),
                icon: rad.attr.find(attr => attr.name === 'data-on-icon').value
            },
            name: 'on-icon-data' + rad.index,
            label: '<em>On</em> icon'
        }
        const $inputOnIcon = this.addIconPicker(onIconData);
        $prettyCheckboxFieldset.append($inputOnIcon);

        // off-icon
        const offIconData = {
            data: {
                index: rad.index,
                prop: 'data-off-icon',
                uniqid: this.makeUniqId(5),
                iconset: $('body').attr('data-icon-font'),
                icon: rad.attr.find(attr => attr.name === 'data-off-icon').value
            },
            name: 'off-icon-data' + rad.index,
            label: '<em>Off</em> icon'
        }
        const $inputOffIcon = this.addIconPicker(offIconData);
        $prettyCheckboxFieldset.append($inputOffIcon);

        // on-color
        const selectOnColorData = {
            data: {
                index: rad.index,
                prop: 'data-on-color'
            },
            name: 'on-color-data' + rad.index,
            label: '<em>On</em> color',
            value: this.getAttributeValue(rad.attr, 'data-on-color')
        }
        const selectOnColorOptions: any = [
            {
                'Default': ''
            },
            {
                'primary': 'primary'
            },
            {
                'Primary outline': 'primary-o'
            },
            {
                'Success': 'success'
            },
            {
                'Success outline': 'success-o'
            },
            {
                'Info': 'info'
            },
            {
                'Info outline': 'info-o'
            },
            {
                'Warning': 'warning'
            },
            {
                'Warning outline': 'warning-o'
            },
            {
                'Danger': 'danger'
            },
            {
                'Danger outline': 'danger-o'
            }
        ];
        const $selectOnColor = this.addSelect(selectOnColorData, selectOnColorOptions);

        $prettyCheckboxFieldset.append($selectOnColor);

        // off-color
        const selectOffColorData = {
            data: {
                index: rad.index,
                prop: 'data-off-color'
            },
            name: 'off-color-data' + rad.index,
            label: '<em>Off</em> color',
            value: this.getAttributeValue(rad.attr, 'data-off-color')
        }
        const selectOffColorOptions: any = [
            {
                'Default': ''
            },
            {
                'primary': 'primary'
            },
            {
                'Primary outline': 'primary-o'
            },
            {
                'Success': 'success'
            },
            {
                'Success outline': 'success-o'
            },
            {
                'Info': 'info'
            },
            {
                'Info outline': 'info-o'
            },
            {
                'Warning': 'warning'
            },
            {
                'Warning outline': 'warning-o'
            },
            {
                'Danger': 'danger'
            },
            {
                'Danger outline': 'danger-o'
            }
        ];
        const $selectOffColor = this.addSelect(selectOffColorData, selectOffColorOptions);

        $prettyCheckboxFieldset.append($selectOffColor);

        $collapsible.append($prettyCheckboxFieldset);

        $fieldset.append($collapsible);

        return $fieldset;
    }
}
