import Components from '../Interfaces/Components';
import Attribute from '../Interfaces/Attribute';
import Pluginz from '../Interfaces/Pluginz';
import CheckboxChkb from '../Interfaces/CheckboxChkb';

import ComponentsMain from './ComponentsMain';

export default class Checkbox extends ComponentsMain implements Components {
    public attr: Array<Attribute>;
    public clazz: string;
    public center: string;
    public componentType: string = 'checkbox';
    public helper: string = '';
    public index: number;
    public inline: string;
    public label: string;
    public name: string;
    public plugins: Array<Pluginz> = [];
    public value: string;
    public width: string;

    public checkboxes: Array<CheckboxChkb> = [
        {
            index: 1,
            text: 'Label 1',
            value: 'value-1',
            disabled: false,
            attr: [
                {
                    name: 'data-color',
                    value: ''
                },
                {
                    name: 'data-icon',
                    value: ''
                },
                {
                    name: 'data-toggle',
                    value: 'false'
                },
                {
                    name: 'data-on-label',
                    value: ''
                },
                {
                    name: 'data-off-label',
                    value: ''
                },
                {
                    name: 'data-on-icon',
                    value: ''
                },
                {
                    name: 'data-off-icon',
                    value: ''
                },
                {
                    name: 'data-on-color',
                    value: ''
                },
                {
                    name: 'data-off-color',
                    value: ''
                }
            ]
        },
        {
            index: 2,
            text: 'Label 2',
            value: 'value-2',
            disabled: false,
            attr: [
                {
                    name: 'data-color',
                    value: ''
                },
                {
                    name: 'data-icon',
                    value: ''
                },
                {
                    name: 'data-toggle',
                    value: 'false'
                },
                {
                    name: 'data-on-label',
                    value: ''
                },
                {
                    name: 'data-off-label',
                    value: ''
                },
                {
                    name: 'data-on-icon',
                    value: ''
                },
                {
                    name: 'data-off-icon',
                    value: ''
                },
                {
                    name: 'data-on-color',
                    value: ''
                },
                {
                    name: 'data-off-color',
                    value: ''
                }
            ]
        }
    ];

    constructor(index, name = 'checkbox', value = '', label = 'Checkbox group', attr = [], center = 'false', inline = 'true', clazz = '', width = '100%') {
        super();
        this.attr = attr;
        this.center = center;
        this.clazz = clazz;
        this.index = index;
        this.inline = inline;
        this.label = `${label} ${index}`;
        this.name = `${name}-${index}`;
        this.value = value;
        this.width = width;

        // merge clazz with attributes for PHP Form Builder
        this.attr = this.mergeAttributes(this);
    }
}
