declare const $: any;
declare const fileTree: any;
declare const loadjs: any;
declare const NiceCheck: any;
declare const OverlayScrollbars: any;
declare const sortable: any;
declare const tata: any;

import { defaultConfig } from './defaultConfig';

import { availableComponents } from './availableComponents';
import { availableComponentSettings } from './availableComponentSettings';
import { availablePlugins } from './availablePlugins';

import Form from './Components/Form';
import FormSettings from './ComponentSettings/Form';

import Attribute from './Interfaces/Attribute';
import Checkbox from './Interfaces/CheckboxChkb';
import ErrorMessage from './Interfaces/ErrorMessage';
import Pluginz from './Interfaces/Pluginz';
import RadioButton from './Interfaces/RadioButton';
import Section from './interfaces/Section';
import SelectOption from './Interfaces/SelectOption';
import Utilities from './utilities/Utilities';

import sha256 = require("crypto-js/sha256");

export default class FormGenerator {

    private componentsIndex: any;
    private componentSettings: any;
    private isCopyEnabled: boolean = false;
    private ui;
    private formSections: Array<any> = [];
    private userForm: Form;
    private userFormSettings: FormSettings;

    constructor() {
        this.ui = defaultConfig.ui;
        this.resetComponentsIndexes();

        loadjs('https://code.jquery.com/jquery-3.4.1.min.js', 'jQuery', { async: false });
        loadjs('lib/html5sortable/html5sortable.min.js', 'html5sortable', { async: false });
        loadjs(['lib/prism/prism.min.css', 'lib/prism/prism.min.js']);

        loadjs.ready('jQuery', () => {
            // Bootstrap JS
            loadjs(['https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js', 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.min.js'], 'core', { async: false });

            // overlayscrollbars
            loadjs(['https://cdnjs.cloudflare.com/ajax/libs/overlayscrollbars/1.13.2/css/OverlayScrollbars.min.css', 'https://cdnjs.cloudflare.com/ajax/libs/overlayscrollbars/1.13.2/js/OverlayScrollbars.min.js'], 'overlayscrollbars');


            // Form plugins
            loadjs(['../phpformbuilder/plugins/nice-check/dist/css/nice-check-blue.min.css', '../phpformbuilder/plugins/nice-check/dist/js/nice-check.min.js'], 'niceCheck');
            loadjs(['../phpformbuilder/plugins/dependent-fields/dependent-fields.min.css', '../phpformbuilder/plugins/dependent-fields/dependent-fields.min.js'], 'dependentFields');
            loadjs(['../phpformbuilder/plugins/slimselect/slimselect.min.css', '../phpformbuilder/plugins/slimselect/themes/bootstrap5.min.css', '../phpformbuilder/plugins/slimselect/slimselect.min.js'], 'slimselect');
            loadjs(['../phpformbuilder/plugins/lcswitch/lc_switch.min.css', '../phpformbuilder/plugins/lcswitch/lc_switch.min.js'], 'lc_switch');

            // Universal Icon Picker
            loadjs(['lib/universal-icon-picker/assets/js/universal-icon-picker.min.js'], 'iconPicker');

            // Filetree
            loadjs(['lib/file-tree-generator/dist/js/file-tree.min.js'], 'fileTree');

            // Toasts (tata.min.js)
            loadjs(['lib/tata/tata.min.js'], 'toasts');

            this.startForm();

            loadjs.ready(['core', 'overlayscrollbars'], () => {
                OverlayScrollbars(document.querySelector('#app main .card-body'), {
                    autoUpdate: true,
                    sizeAutoCapable: false,
                    paddingAbsolute: true
                });
                OverlayScrollbars(document.querySelector('#ui-right-column .card-body'), {
                    autoUpdate: true,
                    sizeAutoCapable: false,
                    paddingAbsolute: true,
                    overflowBehavior: {
                        x: 'hidden'
                    }
                });
            });

            loadjs.ready(['core', 'niceCheck', 'slimselect', 'lc_switch', 'iconPicker'], () => {
                this.loadDefaultUserForm('user-form-settings');
            });

            loadjs.ready(['core', 'iconPicker'], () => {
                this.enableComponentsEvents();
            });

            loadjs.ready(['core', 'iconPicker', 'fileTree', 'toasts'], () => {
                this.enableUIEvents();
            });

            $(window).bind('beforeunload', function () {
                if ($('body').hasClass('has-unsaved-changes')) {
                    return 'Are you sure you want to leave?';
                }
            });
        });

        loadjs.ready('html5sortable', () => {
            this.enableDrag();
        });
    }

    /* User form (main-settings-modal)
    -------------------------------------------------- */

    private loadDefaultUserForm(targetId) {
        this.userForm = new Form();

        // default enable the Formvalidation plugin
        let newPlugin: Pluginz = this.addPlugin('form', 'Formvalidation', '#' + this.userForm.id);
        this.userForm.plugins.push(newPlugin);

        $('body').attr({
            'data-icon-font': this.userForm.iconFont,
            'data-icon-font-url': this.userForm.iconFontUrl
        });

        const section: Section = this.addSection('form', this.userForm, targetId);
        this.userFormSettings = new FormSettings(section);
    }

    private loadIframePreview(formData) {
        $('#preview-modal').find('.modal-body').html('');
        const $iframe = $('<iframe />')
            .attr({
                'name': 'preview-iframe',
                'width': '100%',
                'height': '600px'
            })
            .css('border', 'none');
        $('#preview-modal').find('.modal-body').html($iframe);
        const $form = $('<form />');
        $form.attr({
            method: 'POST',
            action: 'ajax/preview.php',
            target: 'preview-iframe'
        });
        const $input = $('<input />');
        $input.attr({
            type: 'hidden',
            name: 'data',
            value: JSON.stringify(formData)
        });
        $form.append($input);
        $(document.body).append($form);
        $form.submit().remove();
        this.resizePreviewIframe($iframe);
    }

    private loadUserFormFromJson(targetId, jsonFormSection) {
        this.userForm = new Form();
        Object.assign(this.userForm, jsonFormSection.component);

        $('body').attr({
            'data-icon-font': this.userForm.iconFont,
            'data-icon-font-url': this.userForm.iconFontUrl
        });

        // reset sections then add the loaded form
        this.formSections = [];
        const section: Section = this.addSection('form', this.userForm, targetId);

        // load the form plugins from JSON
        const componentPlugins: Array<Pluginz> = [];

        // load the plugin objects from json plugins properties
        jsonFormSection.component.plugins.forEach(plugin => {
            let newPlugin: Pluginz = this.addPlugin('form', plugin.objectName, plugin.selector);
            Object.assign(newPlugin, plugin);
            componentPlugins.push(newPlugin);
        });
        section.component.plugins = componentPlugins;
        this.userForm.plugins = section.component.plugins;
        this.userFormSettings = new FormSettings(section);
    }

    /* Components
    -------------------------------------------------- */

    private addComponent(type: string) {
        // create the Component
        const upperCamelType = Utilities.upperCamelize(type);
        if (availableComponents[upperCamelType] === undefined || availableComponents[upperCamelType] === null) {
            throw new Error(`Class type of \'${upperCamelType}\' is not available`);
        }

        // increment
        this.componentsIndex[type] += 1;
        const i = this.componentsIndex[type];

        return new availableComponents[upperCamelType](i);
    }

    private updateComponent(sectionId, componentProp, value) {
        let comp = this.formSections.find(section => section.id === sectionId).component;
        if (comp.componentType === 'fileuploader' && !this.testUploaderSettings(comp, componentProp)) {
            return;
        }
        comp[componentProp] = value;
        // merge clazz & placeholder with attributes for PHP Form Builder
        if (componentProp === 'clazz' || componentProp === 'placeholder') {
            let attr = componentProp;
            if (attr === 'clazz') {
                attr = 'class';
            }
            if (value.length > 0) {
                this.updateComponentAttr(sectionId, attr, value, true);
            } else {
                this.updateComponentAttr(sectionId, attr, '', false);
            }
        } else if (componentProp === 'name') {
            const compnt = this.formSections.find(section => section.id === sectionId).component;
            if (typeof (compnt.plugins) !== "undefined") {
                compnt.plugins.forEach((compPlugin) => {
                    compPlugin.selector = '#' + value;
                });
            }
        }
    }

    private updateComponentAttr(sectionId, attr, value, isChecked: boolean) {
        const comp = this.formSections.find(section => section.id === sectionId).component;
        if (comp.componentType === 'fileuploader' && !this.testUploaderSettings(comp, attr)) {
            return;
        }
        if (comp.attr !== undefined) {
            if (isChecked === true) {
                const compAttr = comp.attr.find(att => att.name === attr);
                if (compAttr === undefined) {
                    const newAttr: Attribute = {
                        name: attr,
                        value: value
                    }
                    comp.attr.push(newAttr);
                } else {
                    compAttr.value = value;
                }
            } else {
                this.formSections.find(section => section.id === sectionId).component.attr.forEach((compAttr, index) => {
                    if (compAttr.name === attr) {
                        this.formSections.find(section => section.id === sectionId).component.attr.splice(index, 1);
                    }
                });
            }
        }
    }

    private updateComponentItemAttr(itemObject, attrName, value) {
        if (value && value.length > 0) {
            const itemObjectAttr = itemObject.attr.find(att => att.name === attrName);
            if (itemObjectAttr === undefined) {
                const newAttr: Attribute = {
                    name: attrName,
                    value: value
                }
                itemObject.attr.push(newAttr);
            } else {
                itemObjectAttr.value = value;
            }
        } else {
            itemObject.attr.forEach((compAttr, index) => {
                if (compAttr.name === attrName) {
                    itemObject.attr.splice(index, 1);
                }
            });
        }
    }

    private updateComponentDefaultValue(items, defaultValue) {
        let values = items.map(i => i.value);
        if (values.indexOf(defaultValue) === -1) {
            defaultValue = '';
        }
        const $selectDefaultValue = this.componentSettings.buildSelectDefaultValue(defaultValue, items);
        // update the default value select
        $('#tab-main select[name="value"]').closest('.form-group').replaceWith($selectDefaultValue);
    }

    /* Component settings
    -------------------------------------------------- */

    private loadComponentSettings(sectionId) {
        const currentSection = this.formSections.find(section => section.id === sectionId);

        const upperCamelType = Utilities.upperCamelize(currentSection.componentType) + 'Settings';
        if (availableComponentSettings[upperCamelType] === undefined || availableComponentSettings[upperCamelType] === null) {
            throw new Error(`Class type of \'${upperCamelType}\' is not available`);
        }

        this.componentSettings = new availableComponentSettings[upperCamelType](currentSection);
    }

    /* Events
    -------------------------------------------------- */

    private toggleCopyButtons(e) {
        const isCtrlPressed = e.ctrlKey;
        if (isCtrlPressed && !this.isCopyEnabled) {
            $('.btn-drag-copy').removeClass('d-none');
            $('.btn-drag-move').addClass('d-none');
            sortable('#' + this.ui.form.id, {
                copy: true
            });
        } else if (!isCtrlPressed && this.isCopyEnabled) {
            $('.btn-drag-copy').addClass('d-none');
            $('.btn-drag-move').removeClass('d-none');
            sortable('#' + this.ui.form.id, {
                copy: false
            });
        }
        this.isCopyEnabled = isCtrlPressed;
    }

    private enableDrag() {

        // enable ctrl key for copy
        document.addEventListener('keydown', (e) => {
            this.toggleCopyButtons(e);
        });

        // enable ctrl key for copy
        document.addEventListener('keyup', (e) => {
            this.toggleCopyButtons(e);
        });

        /* sidebar -> form
        -------------------------------------------------- */
        sortable('#' + this.ui.form.id, {
            acceptFrom: '#sidebar-components, #' + this.ui.form.id,
            placeholder: this.ui.droppableTemplate,
            // forcePlaceholderSize: true,
            items: 'section'
        });
        sortable('#sidebar-components', {
            copy: true,
            acceptFrom: false,
            placeholder: ''
        });
        sortable('#' + this.ui.form.id)[0].addEventListener('sortstart', () => {
            if (this.isCopyEnabled) {
                $('*[aria-copied="true"]').removeClass('d-flex').addClass('d-none');
            }
        });
        sortable('#' + this.ui.form.id)[0].addEventListener('sortupdate', (e) => {
            if (e.detail.origin.container === $('#sidebar-components')[0]) {
                const type = e.detail.item.dataset.component;
                // add the object component
                const comp = this.addComponent(type);
                // add the section
                const section = this.addSection(type, comp);
                // render the section
                this.renderSection(type, section, e.detail.item, false, () => {
                    this.reindexSections();
                });
            } else {
                if (this.isCopyEnabled) {
                    const newSectionId = 's-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                    this.duplicateSection(e.detail.item.id, newSectionId);
                    $('*[aria-copied="true"]').addClass('d-flex').removeClass('d-none').attr('id', newSectionId);
                    this.reloadSection(newSectionId);
                }
                this.reindexSections();
            }
            this.hasUnsavedChanges(true);
        });
    }

    private enableComponentsEvents() {
        let timeout = null;
        const timeoutDelay: number = 600;

        $('#' + this.ui.form.id).off('submit').on('submit', function (e) {
            e.preventDefault();
            return false;
        });

        // activate section
        $('#' + this.ui.form.id).off('click', 'section').on('click', 'section', (e) => {
            e.stopPropagation();
            $('#' + this.ui.form.id).find('section.active').removeClass(this.ui.sectionActiveClass);
            const $currentSection = $(e.target).closest('section');
            $currentSection.addClass(this.ui.sectionActiveClass);
            this.loadComponentSettings($currentSection.attr('id'));
            return false;
        });

        // hide popover
        $('#' + this.ui.form.id + ', #components-settings').off('click', '.delete-cancel, .delete-confirm').on('click', '.delete-cancel, .delete-confirm', () => {
            $('[data-bs-toggle=popover]').popover('hide');
        });

        // delete component
        $('#' + this.ui.form.id).off('click', '.delete-confirm').on('click', '.delete-confirm', (e) => {
            const sectionId = $(e.target).closest('.btn-group').data('section-id');
            const sectionIndex = this.formSections.findIndex(function (sect) { return sect.id === sectionId });
            const componentType = this.formSections[sectionIndex].componentType;
            // decrease componentType index
            this.componentsIndex[componentType] -= 1;
            // delete component Object
            this.formSections.splice(sectionIndex, 1);
            // empty the components-settings
            $('#components-settings').html('');

            $('#' + sectionId).remove();
            $('.popover').remove();
            this.hasUnsavedChanges(true);
            return false;
        });

        $('#' + this.ui.form.id).off('click', '.delete-cancel').on('click', '.delete-cancel', () => {
            $('.popover').remove();
        });

        // Autosize textareas
        $('#components-settings').off('keyup', '#components-settings-form textarea').on('keyup', '#components-settings-form textarea', (e) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                $(e.target).css({
                    height: e.target.scrollHeight + 2 + 'px'
                });
            }, timeoutDelay);
        });

        /* Components settings - All tabs
        -------------------------------------------------- */

        $('#components-settings').off('click', '.collapse-all-btn').on('click', '.collapse-all-btn', (e) => {
            e.preventDefault();
            $(e.target).closest('.tab-pane').find('.collapse').collapse('hide');
            return false;
        });

        $('#components-settings').off('click', '.expand-all-btn').on('click', '.expand-all-btn', (e) => {
            e.preventDefault();
            $(e.target).closest('.tab-pane').find('.collapse').collapse('show');
            return false;
        });

        $('#components-settings').off('hide.bs.collapse', '.collapse').on('hide.bs.collapse', '.collapse', (e) => {
            $(e.target).closest('fieldset').addClass('collapsed');
        });

        $('#components-settings').off('show.bs.collapse', '.collapse').on('show.bs.collapse', '.collapse', (e) => {
            $(e.target).closest('fieldset').removeClass('collapsed');
        });

        /* Components settings - Main tab
        -------------------------------------------------- */

        // input change
        $('#components-settings').off('keyup', '#tab-main input, #tab-main textarea').on('keyup', '#tab-main input, #tab-main textarea', (e) => {
            let value = $(e.target).val();
            const pattern = $(e.target).prop('pattern');
            let wrongPattern = false;
            if (pattern) {
                const regex = new RegExp('^' + pattern + '$');
                if (!regex.test(value)) {
                    $(e.target).addClass('is-invalid');
                    wrongPattern = true;
                }
            }
            if (!wrongPattern) {
                $(e.target).removeClass('is-invalid');
                const $section = $(e.target).closest('section');
                const sectionId = $section.data('targetId');
                const componentProp = $(e.target).data('prop');
                const dependentFieldSections = this.formSections.filter(section => section.componentType === 'dependent');
                if (dependentFieldSections.length > 0) {
                    if (componentProp === 'name') {
                        const compOriginalName = this.formSections.find(section => section.id === sectionId).component.name;
                        dependentFieldSections.forEach(depSect => {
                            if (depSect.component.name === compOriginalName) {
                                depSect.component.name = value;
                                this.reloadSection(depSect.id);
                            }
                        });
                    }
                }

                this.updateComponent(sectionId, componentProp, value);
                this.hasUnsavedChanges(true);

                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    this.reloadSection(sectionId);
                }, timeoutDelay);
            }
        });

        // select change
        $('#components-settings').off('change', '#tab-main select').on('change', '#tab-main select', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentType = $section.data('componentType');
            const componentProp = $(e.target).data('prop');
            const value = $(e.target).val();
            $('#' + sectionId).find(componentType).prop(componentProp, value);

            this.updateComponent(sectionId, componentProp, value);
            this.reloadSection(sectionId);
            this.hasUnsavedChanges(true);
        });

        // checkbox change
        $('#components-settings').off('change', '#tab-main input[type="checkbox"]').on('change lcs-statuschange', '#tab-main input[type="checkbox"]', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentType = $section.data('componentType');
            const componentProp = $(e.target).data('prop');
            const value = $(e.target).val();
            if ($(e.target).is(':checked')) {
                $('#' + sectionId).find(componentType).attr(componentProp, value);
            } else {
                $('#' + sectionId).find(componentType).removeAttr(componentProp);
            }

            this.updateComponentAttr(sectionId, componentProp, value, $(e.target).is(':checked'));
            this.reloadSection(sectionId);
            this.hasUnsavedChanges(true);
        });

        // iconpicker change
        $('#components-settings').off('change', '#tab-main button[role="iconpicker"]').on('change', '#tab-main button[role="iconpicker"]', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentType = $section.data('componentType');
            const componentProp = $(e.target).data('prop');
            let value = $(e.target).data('icon');
            $('#' + sectionId).find(componentType).prop(componentProp, value);
            this.updateComponent(sectionId, componentProp, value);
            this.reloadSection(sectionId);
            this.hasUnsavedChanges(true);
        });

        /* Components settings - SelectOptions + radio btns + checkboxes + button group tabs
        ------------------------------------------------------------------------------------ */

        $('#components-settings').on('change keyup', '#tab-items input', (e) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const $section = $(e.target).closest('section');
                const sectionId = $section.data('targetId');
                const itemIndex = $(e.target).data('index');
                const itemProp = $(e.target).data('prop');
                let itemValue = $(e.target).val();
                if (($(e.target).is(':checkbox') || $(e.target).is(':radio')) && !$(e.target).is(':checked')) {
                    itemValue = false;
                }
                const component = this.formSections.find(section => section.id === sectionId).component;
                switch (component.componentType) {
                    case 'buttongroup':
                        this.updateButtonFromButtongroup(sectionId, itemIndex, itemProp, itemValue);
                        break;
                    case 'select':
                        this.updateSelectOption(sectionId, itemIndex, itemProp, itemValue);
                        break;

                    case 'radio':
                        const rad = component.radioButtons.find(radioButtons => radioButtons.index === itemIndex);
                        if (rad.attr.filter(ev => ev.name === itemProp).length > 0) {
                            // if itemProp exists in the radioButton attr keys
                            this.updateComponentItemAttr(rad, itemProp, itemValue);
                        } else {
                            this.updateRadioButton(sectionId, itemIndex, itemProp, itemValue);
                        }
                        break;

                    case 'checkbox':
                        const chk = component.checkboxes.find(checkboxes => checkboxes.index === itemIndex);
                        if (chk.attr.filter(ev => ev.name === itemProp).length > 0) {
                            // if itemProp exists in the checkbox attr keys
                            this.updateComponentItemAttr(chk, itemProp, itemValue);
                        } else {
                            this.updateCheckbox(sectionId, itemIndex, itemProp, itemValue);
                        }
                        break;

                    default:
                        break;
                }
                this.hasUnsavedChanges(true);
            }, timeoutDelay);
        });

        // checkbox change
        $('#components-settings').off('change', '#tab-items input[type="checkbox"]').on('change lcs-statuschange', '#tab-items input[type="checkbox"]', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentType = $section.data('componentType');
            const itemIndex = $(e.target).data('index');
            const itemProp = $(e.target).data('prop');
            const itemValue = $(e.target).val();
            const cpt = this.formSections.find(section => section.id === sectionId).component;
            if ($(e.target).is(':checked')) {
                $('#' + sectionId).find(componentType).attr(itemProp, itemValue);
            } else {
                $('#' + sectionId).find(componentType).removeAttr(itemProp);
            }
            switch (cpt.componentType) {
                case 'buttongroup':
                    // not implemented
                    break;
                case 'select':
                    // not implemented
                    break;

                case 'radio':
                    const rad = cpt.radioButtons.find(radioButtons => radioButtons.index === itemIndex);
                    this.updateComponentItemAttr(rad, itemProp, $(e.target).is(':checked'));
                    break;

                case 'checkbox':
                    const chk = cpt.checkboxes.find(checkboxes => checkboxes.index === itemIndex);
                    this.updateComponentItemAttr(chk, itemProp, $(e.target).is(':checked'));
                    break;

                default:
                    break;
            }
        });

        // select change
        $('#components-settings').off('change', '#tab-items select').on('change', '#tab-items select', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const itemIndex = $(e.target).data('index');
            const itemProp = $(e.target).data('prop');
            const itemValue = $(e.target).val();
            const cpt = this.formSections.find(section => section.id === sectionId).component;
            switch (cpt.componentType) {
                case 'buttongroup':
                    if (itemIndex === undefined) {
                        this.updateComponent(sectionId, itemProp, itemValue);
                        this.reloadSection(sectionId);
                    } else {
                        this.updateButtonFromButtongroup(sectionId, itemIndex, itemProp, itemValue);
                    }
                    this.hasUnsavedChanges(true);
                    break;
                case 'select':
                    // not implemented
                    break;

                case 'radio':
                    const rad = cpt.radioButtons.find(radioButtons => radioButtons.index === itemIndex);
                    this.updateComponentItemAttr(rad, itemProp, itemValue);
                    break;

                case 'checkbox':
                    const chk = cpt.checkboxes.find(checkboxes => checkboxes.index === itemIndex);
                    this.updateComponentItemAttr(chk, itemProp, itemValue);
                    break;

                default:
                    break;
            }
        });

        // iconpicker change (tab-items buttongroup)
        $('#components-settings').off('change', '#tab-items button[role="iconpicker"]').on('change', '#tab-items button[role="iconpicker"]', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const itemIndex = $(e.target).data('index');
            const itemProp = $(e.target).data('prop');
            let itemValue = $(e.target).data('icon');
            const cpt = this.formSections.find(section => section.id === sectionId).component;
            if (cpt.componentType === 'checkbox') {
                const chk = cpt.checkboxes.find(checkboxes => checkboxes.index === itemIndex);
                this.updateComponentItemAttr(chk, itemProp, itemValue);
            } else if (cpt.componentType === 'radio') {
                const rad = cpt.radioButtons.find(radioButtons => radioButtons.index === itemIndex);
                this.updateComponentItemAttr(rad, itemProp, itemValue);
            } else {
                this.updateButtonFromButtongroup(sectionId, itemIndex, itemProp, itemValue);
            }
            this.hasUnsavedChanges(true);
        });

        $('#components-settings').off('click', '#tab-items .add-btn').on('click', '#tab-items .add-btn', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentType = this.formSections.find(section => section.id === sectionId).component.componentType;
            switch (componentType) {
                case 'select':
                    this.addSelectOption(sectionId);
                    break;

                case 'radio':
                    this.addRadioButton(sectionId);
                    break;

                case 'checkbox':
                    this.addCheckbox(sectionId);
                    break;

                default:
                    break;
            }
            this.hasUnsavedChanges(true);
        });

        $('#components-settings').off('click', '#tab-items .delete-confirm').on('click', '#tab-items .delete-confirm', (e) => {
            const $fieldset = $(e.target).closest('fieldset');
            const sectionId = $fieldset.data('sectionId');
            const componentType = $fieldset.data('componentType');
            const itemIndex = $fieldset.data('itemIndex');
            switch (componentType) {
                case 'select':
                    this.deleteSelectOption(sectionId, itemIndex);
                    break;

                case 'radio':
                    this.deleteRadioButton(sectionId, itemIndex);
                    break;

                case 'checkbox':
                    this.deleteCheckbox(sectionId, itemIndex);
                    break;

                default:
                    break;
            }
            this.hasUnsavedChanges(true);

            return false;
        });

        /* Components settings - Plugins tab
        -------------------------------------------------- */

        // add plugin
        $('#components-settings').off('change', '#tab-plugins select.plugins-select').on('change', '#tab-plugins select.plugins-select', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const value = $(e.target).val();
            const comp = this.formSections.find(section => section.id === sectionId).component;
            value.forEach(objectName => {
                // find the new plugin from the multiple select values
                const index: number = comp.plugins.findIndex(function (Pl) { return Pl.constructor.name === objectName });
                if (index === -1) {
                    // create the plugin object
                    const newPlugin: Pluginz = this.addPlugin(comp.componentType, objectName, '#' + comp.name);
                    // add the plugin to component
                    comp.plugins.push(newPlugin);
                    // render the plugin fields from ComponentSettings/ComponentSettings.ts
                    const $pluginFieldset = this.componentSettings.loadPlugin(newPlugin, this.componentSettings);
                    this.componentSettings.appendPlugin($pluginFieldset);
                    if (objectName === 'BootstrapInputSpinner') {
                        // set the input type to 'number'
                        let inputType = $('#tab-main select[name="type"] option:selected').val();
                        if (inputType !== 'number') {
                            let el = $('#tab-main select[name="type"]')[0];
                            el.slim.set('number');
                            $('#tab-main select[name="type"]').trigger('change')
                        }
                    }
                    const formErrors = this.testFormConsistency();
                    if (formErrors.length > 0) {
                        this.throwErrors(formErrors);
                    }
                }
            });
            // remove plugin
            comp.plugins.forEach(pl => {
                if (value.indexOf(pl.objectName) === -1) {
                    const index: number = comp.plugins.findIndex(function (P) { return P.constructor.name === pl.objectName });
                    // remove the plugin object
                    delete comp.plugins[index];
                    // reindex
                    comp.plugins = comp.plugins.filter(val => val);
                    // remove the plugin from DOM
                    $section.find('fieldset.' + pl.objectName + '-fieldset').remove();
                }
            });
            this.hasUnsavedChanges(true);
        });

        // select change
        $('#components-settings').off('change', '#tab-plugins select:not(.plugins-select)').on('change', '#tab-plugins select:not(.plugins-select)', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const value = $(e.target).val();
            const componentPluginData = $(e.target).data();
            this.updateComponentPlugin(sectionId, componentPluginData, value);
            this.hasUnsavedChanges(true);
        });

        // input change | textarea change
        $('#components-settings').off('keyup', '#tab-plugins input, #tab-plugins textarea').on('keyup', '#tab-plugins input, #tab-plugins textarea', (e) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const $section = $(e.target).closest('section');
                const sectionId = $section.data('targetId');
                const componentPluginData = $(e.target).data();
                let value = $(e.target).val();
                this.updateComponentPlugin(sectionId, componentPluginData, value);
                this.hasUnsavedChanges(true);
            }, timeoutDelay);
        });

        // checkbox change
        $('#components-settings').off('change', '#tab-plugins input[type="checkbox"]').on('change lcs-statuschange', '#tab-plugins input[type="checkbox"]', (e) => {
            const $section = $(e.target).closest('section');
            const sectionId = $section.data('targetId');
            const componentPluginData = $(e.target).data();
            let value = $(e.target).val();
            this.updateComponentPlugin(sectionId, componentPluginData, value, $(e.target).is(':checked'));
            this.hasUnsavedChanges(true);
        });

        /* User form (Form settings modal)
        -------------------------------------------------- */

        $('#user-form-settings').off('keyup', '#user-form-settings-main input, #user-form-settings-action input, #collapsible-form-actions input').on('keyup', '#user-form-settings-main input, #user-form-settings-action input, #collapsible-form-actions input', (e) => {
            const formProp = $(e.target).data('prop');
            let value = $(e.target).val();

            this.userForm[formProp] = value;

            if (formProp === 'id') {
                this.userForm.plugins.forEach(pl => {
                    if (pl.pluginName === 'formvalidation') {
                        pl.selector = '#' + value;
                    }
                });
            }
            this.hasUnsavedChanges(true);
        });

        // select change
        $('#user-form-settings').off('change', '#user-form-settings-main select, #user-form-settings-action select').on('change', '#user-form-settings-main select, #user-form-settings-action select', (e) => {
            const formProp = $(e.target).data('prop');
            const value = $(e.target).val();

            this.userForm[formProp] = value;
            if (formProp === 'layout') {
                if (value === 'horizontal') {
                    $('#' + this.ui.form.id).removeClass('form-inline').addClass('form-horizontal');
                } else {
                    if (value === 'inline') {
                        $('#' + this.ui.form.id).removeClass('form-horizontal').addClass('form-inline');
                    } else {
                        $('#' + this.ui.form.id).removeClass('form-horizontal').removeClass('form-inline');
                    }
                    // reset all components width to 100%
                    this.formSections.forEach(section => {
                        if (section.component.width !== undefined) {
                            section.component.width = '100%';
                            $('#' + section.id).removeClass('w-33 w-50 w-66').addClass('w-100');
                        }
                    });
                }
                if ($('#' + this.ui.form.id).find('section.active')[0]) {
                    // refresh the active section to enable/disable the 'width' select according to the form layout
                    $('#' + this.ui.form.id).find('section.active').trigger('click');
                }
            } else if (formProp === 'aftervalidation') {
                $('fieldset[data-parent="#collapsible-form-actions"]').collapse('hide');
                $('#' + value).collapse('show');
            } else if (formProp === 'iconFont') {
                Object.keys(defaultConfig.ui.iconLibraries).forEach(key => {
                    if (defaultConfig.ui.iconLibraries[key]['value'] === value) {
                        this.userForm.iconFont = defaultConfig.ui.iconLibraries[key]['value'];
                        this.userForm.iconFontUrl = defaultConfig.ui.iconLibraries[key]['url'];
                        $('body').attr({
                            'data-icon-font': this.userForm.iconFont,
                            'data-icon-font-url': this.userForm.iconFontUrl
                        });
                        if ($('button[role="iconpicker"]')[0]) {
                            $('button[role="iconpicker"]').attr('data-iconset', this.userForm.iconFont);
                        }
                    }
                });

            }
            this.hasUnsavedChanges(true);
        });

        // checkbox change
        $('#user-form-settings').off('change', '#user-form-settings-ajax input[type="checkbox"]').on('change lcs-statuschange', '#user-form-settings-ajax input[type="checkbox"]', (e) => {
            const formProp = $(e.target).data('prop');
            let value = 'false';
            if ($(e.target).is(':checked')) {
                value = 'true';
            }
            this.userForm[formProp] = value;
            this.hasUnsavedChanges(true);
        });

        /* User form - Plugins
        -------------------------------------------------- */

        $('#user-form-settings').off('change', '#user-form-settings-plugins select.plugins-select').on('change', '#user-form-settings-plugins select.plugins-select', (e) => {
            const value = $(e.target).val();
            const form = this.userForm;
            // add plugin
            value.forEach(objectName => {
                // find the new plugin from the multiple select values
                const index: number = form.plugins.findIndex(function (Pl) { return Pl.constructor.name === objectName });
                if (index === -1) {
                    // create the plugin object
                    let selector = 'form';
                    if (objectName === 'Icheck') {
                        selector = 'input';
                    } else if (objectName === 'Formvalidation') {
                        selector = '#' + this.userForm.id;
                    }
                    const newPlugin: Pluginz = this.addPlugin('form', objectName, selector);
                    // add the plugin to component
                    form.plugins.push(newPlugin);
                    // render the plugin fields from ComponentSettings/ComponentSettings.ts
                    const $pluginFieldset = this.userFormSettings.loadPlugin(newPlugin, this.userFormSettings);
                    $('#user-form-settings-plugins').append($pluginFieldset);
                    this.userFormSettings.refreshComponentSettingsFormPlugins($('#user-form-settings-plugins'));
                    if (objectName === 'PrettyCheckbox') {
                        // Show the PrettyCheckbox checkbox/radio settings
                        if ($('.pretty-checkbox-fieldset')[0]) {
                            $('.pretty-checkbox-fieldset').addClass('active');
                        }
                    }
                }
            });
            // remove plugin
            form.plugins.forEach(pl => {
                if (value.indexOf(pl.objectName) === -1) {
                    const index: number = form.plugins.findIndex(function (P) { return P.constructor.name === pl.objectName });
                    // remove the plugin object
                    form.plugins.splice(index, 1);
                    // reindex
                    form.plugins = form.plugins.filter(val => val);
                    // remove the plugin from DOM
                    $('#user-form-settings-plugins').find('fieldset.' + pl.objectName + '-fieldset').remove();
                    if (pl.objectName === 'PrettyCheckbox') {
                        // Hide the PrettyCheckbox checkbox/radio settings
                        if ($('.pretty-checkbox-fieldset')[0]) {
                            $('.pretty-checkbox-fieldset').removeClass('active');
                        }
                    }
                }
            });
            this.hasUnsavedChanges(true);
        });

        // select change
        $('#user-form-settings').off('change', '#user-form-settings-plugins select:not(.plugins-select)').on('change', '#user-form-settings-plugins select:not(.plugins-select)', (e) => {
            const value = $(e.target).val();
            const componentPluginData = $(e.target).data();
            this.updateComponentPlugin('user-form-settings', componentPluginData, value);
            this.hasUnsavedChanges(true);
        });

        // input change | textarea change
        $('#user-form-settings').off('keyup', '#user-form-settings-plugins input, #user-form-settings-plugins textarea').on('keyup', '#user-form-settings-plugins input, #user-form-settings-plugins textarea', (e) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                let value = $(e.target).val();
                const componentPluginData = $(e.target).data();
                this.updateComponentPlugin('user-form-settings', componentPluginData, value);
                this.hasUnsavedChanges(true);
            }, timeoutDelay);
        });

        // checkbox change
        $('#user-form-settings').off('change lcs-statuschange', '#user-form-settings-plugins input[type="checkbox"]').on('change, lcs-statuschange', '#user-form-settings-plugins input[type="checkbox"]', (e) => {
            const componentPluginData = $(e.target).data();
            let value = $(e.target).val();
            this.updateComponentPlugin('user-form-settings', componentPluginData, value, $(e.target).is(':checked'));
            this.hasUnsavedChanges(true);
        });
    }

    private enableUIEvents() {
        $('#ui-icon-bars-left-column a').on('click', (e) => {
            e.preventDefault();
            $('#ui-icon-bars-left-column, #ui-left-column').toggleClass('ui-hidden');

            return false;
        });

        $('#ui-icon-bars-right-column a').on('click', (e) => {
            e.preventDefault();
            $('#ui-icon-bars-right-column, #ui-right-column').toggleClass('ui-hidden');

            return false;
        });

        $('#save-json-to-disk-btn').on('click', () => {
            this.downloadObjectAsJson();
            this.hasUnsavedChanges(false);

            return false;
        });

        $('#load-json-from-disk-modal').on('shown.bs.modal', function () {
            $(this).find('#json-file-disk-load-btn').prop('disabled', true);
            $(this).find('#json-file-disk-output').html('');
            $('#json-file-disk-input').val(null);
        });

        $('#json-file-disk-input').on('change', (e) => {
            const selectedFile = e.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsText(selectedFile, "UTF-8");
            fileReader.onload = () => {
                const result = fileReader.result as string;
                const loadedJson = JSON.parse(result);
                $('#json-file-disk-output').html(`<p class="alert alert-info mt-4"><strong>${loadedJson.userForm.id}</strong> has been uploaded.<br>Click the <span class="badge badge-primary">Load</span> button to load the form into the editor.</p>`);
                $('#json-file-disk-load-btn').removeAttr("disabled").off('click').on('click', () => {
                    this.loadFormFromJson(loadedJson);
                    $('#load-json-from-disk-modal').modal('hide');
                    this.hasUnsavedChanges(false);
                });
            }
            fileReader.onerror = (error) => {
                console.log(error);
            }
        });

        $('#load-json-from-server-modal').on('shown.bs.modal', (e) => {
            let ftree: any;
            const salt: string = '%t$qPP';
            const cwd = window.location.pathname.split('/').slice(1, -1).join('/');
            // console.log(cwd);
            const options = {
                mainDir: cwd + '/json-forms',
                explorerMode: 'grid',
                extensions: ['.json'],
                cancelBtn: false,
                okBtn: false,
                elementClick: (filePath, fileName, el) => {
                    if ($(el.target).hasClass('ft-delete-file')) {
                        const filehash = sha256(fileName + salt);
                        $(el.target).popover({
                            html: true,
                            container: $('#json-forms-file-tree-wrapper'),
                            content: () => {

                                return $(`<div><h5>Delete?</h5>
                        <div class="btn-group" role="group" aria-label="Delete?">
                            <button type="button" class="btn btn-sm btn-secondary delete-cancel">No</button>
                            <button type="button" class="btn btn-sm btn-danger delete-confirm" data-filepath="${filePath}" data-filename="${fileName}" data-filehash="${filehash}">Yes</button>
                        </div></div>`);
                            }
                        });

                        $('#json-forms-file-tree-wrapper').off('click', '.delete-cancel').on('click', '.delete-cancel', () => {
                            $('[data-bs-toggle=popover]').popover('hide');
                        });

                        $('#json-forms-file-tree-wrapper').off('click', '.delete-confirm').on('click', '.delete-confirm', (elt) => {
                            if (window.location.hostname.match('`phpformbuilder.pro$`')) {
                                $('#demo-server-delete-disabled').html(`<p class="alert alert-warning has-icon">This feature is disabled in the demo</p>`);
                                return false;
                            }
                            $.ajax({
                                url: 'ajax/delete-json-form.php',
                                type: 'POST',
                                data: {
                                    filename: $(elt.target).data('filename'),
                                    filepath: $(elt.target).data('filepath').replace($(elt.target).data('filename'), ''),
                                    filehash: $(elt.target).data('filehash')
                                }
                            }).done((data) => {
                                const result = JSON.parse(data);
                                if (result.status !== undefined) {
                                    if (result.status === 'success') {
                                        tata.success('', result.msg);
                                        const folderId = ftree.currentFolderId;
                                        for (let index = 0; index < ftree.foldersContent[folderId]['files'].length; index++) {
                                            const file = ftree.foldersContent[folderId]['files'][index];
                                            if (file.name === $(e.target).data('filename')) {
                                                delete ftree.foldersContent[folderId]['files'][index];
                                            }
                                        }
                                        ftree.loadFolder(folderId);
                                    } else {
                                        tata.danger('', result.msg);
                                    }
                                }
                            }).fail(function (_data, _statut, error) {
                                console.log(error);
                            });
                            $('.popover').remove();
                        });
                    } else {
                        let loadedJson;
                        const xhr = new XMLHttpRequest();
                        xhr.onreadystatechange = () => {
                            if (xhr.readyState == 4 && xhr.status == 200) {
                                const fileReader = new FileReader();
                                fileReader.readAsText(xhr.response, "UTF-8");
                                fileReader.onload = () => {
                                    const result = fileReader.result as string;
                                    loadedJson = JSON.parse(result);
                                }
                                fileReader.onerror = (error) => {
                                    console.log(error);
                                }
                            }
                        }
                        xhr.open('GET', '/' + filePath);
                        xhr.responseType = 'blob';
                        xhr.send();
                        $('#json-file-server-load-btn').removeAttr("disabled").off('click').on('click', () => {
                            this.loadFormFromJson(loadedJson);
                            $('#load-json-from-server-modal').modal('hide');
                            this.hasUnsavedChanges(false);
                        });
                    }
                }
            };

            ftree = new fileTree('json-forms-file-tree-wrapper', options);
            $(this).find('#json-file-server-load-btn').prop('disabled', true);
            $(this).find('#json-file-server-output').html('');
        });

        $('#save-json-on-server-modal').on('shown.bs.modal', () => {
            const cwd = window.location.pathname.split('/').slice(1, -1).join('/');
            const options = {
                mainDir: cwd + '/json-forms',
                explorerMode: 'grid',
                dragAndDrop: false,
                loadFolder: false,
                modeSwitcher: false,
                extensions: [''],
                cancelBtn: false,
                okBtn: false,
                callback: () => {
                    $('#json-file-server-save-btn').attr('disabled', true);
                    $('#save-json-on-server-modal .ft-folder-container').on('click', (el) => {
                        $('#save-json-on-server-modal .ft-folder-container a').removeClass('folder-selected');
                        $(el.target).addClass('folder-selected');
                        const filepath: string = $(el.target).attr('data-url');
                        $('#json-file-server-save-btn').removeAttr("disabled").off('click').on('click', () => {
                            if (window.location.hostname.match('`phpformbuilder.pro$`')) {
                                $('#demo-server-save-disabled').html(`<p class="alert alert-warning has-icon">This feature is disabled in the demo</p>`);
                                return false;
                            }
                            $('#save-json-on-server-modal').modal('hide');
                            this.saveJsonFormOnServer(filepath);
                        });
                    });
                }
            };

            let _ftree = new fileTree('save-on-server-file-tree-wrapper', options);
        });

        $('#preview-btn').on('click', (e) => {
            const formData = {
                userForm: this.userForm,
                formSections: this.formSections
            };
            const formErrors = this.testFormConsistency();
            if (formErrors.length > 0) {
                this.throwErrors(formErrors);
                e.preventDefault();
                return false;
            } else {
                this.loadIframePreview(formData);
            }
        });
        $('#get-code-btn').on('click', (e) => {
            const formData = {
                userForm: this.userForm,
                formSections: this.formSections
            };
            const formErrors = this.testFormConsistency();
            if (formErrors.length > 0) {
                this.throwErrors(formErrors);
                e.preventDefault();
                return false;
            } else {
                $.ajax({
                    url: `ajax/get-code.php`,
                    type: 'POST',
                    data: { 'data': JSON.stringify(formData) }
                }).done((data) => {
                    $('#get-code-modal .modal-body').html(data);
                }).fail(function (_data, _statut, error) {
                    console.log(error);
                });
            }
        });
    }

    /* Form
    -------------------------------------------------- */

    private downloadObjectAsJson() {
        let exportFormSections: Array<Section> = [];
        this.formSections.forEach(sect => {
            exportFormSections.push(sect);
        });
        const exportObj = {
            formSections: exportFormSections,
            uiForm: this.ui.form,
            userForm: this.userForm
        }
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", this.userForm.id + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    private loadFormFromJson(jsonForm) {
        this.resetComponentsIndexes();
        this.ui.form = jsonForm.uiForm;
        const jsonFormSection = jsonForm.formSections.find(section => section.componentType === 'form');
        // load the user form + the settings in modal
        this.loadUserFormFromJson('user-form-settings', jsonFormSection);
        this.startForm();
        this.enableComponentsEvents();
        this.enableDrag();

        jsonForm.formSections.forEach(jsonSection => {
            if (jsonSection.componentType !== 'form') {
                let comp;
                let section;

                // add the component object
                comp = this.addComponent(jsonSection.componentType);
                Object.assign(comp, jsonSection.component);

                // load the plugin objects from json plugins properties
                const componentPlugins: Array<Pluginz> = [];
                if (typeof (comp.plugins) !== "undefined") {
                    comp.plugins.forEach(plugin => {
                        let newPlugin: Pluginz = this.addPlugin(comp.componentType, plugin.objectName, plugin.selector);
                        Object.assign(newPlugin, plugin);
                        componentPlugins.push(newPlugin);
                    });
                    comp.plugins = componentPlugins;
                }

                // add the section
                section = this.addSection(jsonSection.componentType, comp, jsonSection.id);
                $('#' + this.ui.form.id).append(`<div id="placeholder-${section.id}" />`);
                // render the section
                this.renderSection(section.componentType, section, '#placeholder-' + section.id, false);
            }
        });
    }

    private saveJsonFormOnServer(filePath: string) {
        let exportFormSections: Array<Section> = [];
        this.formSections.forEach(sect => {
            exportFormSections.push(sect);
        });
        const exportObj = {
            formSections: exportFormSections,
            uiForm: this.ui.form,
            userForm: this.userForm
        }
        $.ajax({
            url: `ajax/save-json-form.php`,
            type: 'POST',
            data: {
                'data': JSON.stringify(exportObj),
                'filepath': JSON.stringify(filePath)
            }
        }).done((data) => {
            const json = JSON.parse(data);
            $('#save-json-from-server-modal').modal('hide');
            if (json.status === 'danger') {
                tata.error('Failed to save the form on server', json.msg);
            } else {
                this.hasUnsavedChanges(false);
                tata.success('Form saved on server', json.msg);
            }
        }).fail(function (_data, _statut, error) {
            console.log(error);
        });
    }

    /* Plugins
    -------------------------------------------------- */

    private addPlugin(componentType, pluginName: string, selector: string) {
        // create the Plugin
        const upperCamelPluginName = Utilities.upperCamelize(pluginName);
        if (availablePlugins[componentType] === undefined) {
            throw new Error(`\'${componentType}\' is not available in availablePlugins`);
        }
        const index: number = availablePlugins[componentType].findIndex(function (Pl) { return Pl.name === upperCamelPluginName });
        if (index === -1) {
            throw new Error(`Class type of \'${upperCamelPluginName}\' is not available in ${componentType}`);
        }

        return new availablePlugins[componentType][index](selector);
    }

    private updateComponentPlugin(sectionId, componentPluginData, value, isChecked: boolean = true) {
        // isChecked is used only for dataAttributes + form->modal & form->popover
        const compPlugins: Array<Pluginz> = this.formSections.find(section => section.id === sectionId).component.plugins;
        const plugin = compPlugins.find(pl => pl.objectName == componentPluginData.plugin);
        if (componentPluginData.prop === 'replacements') {
            if (plugin.objectName === 'Icheck') {
                this.updateIcheckPlugin(plugin, componentPluginData, value);
                return;
            }
            const replacementkey: string = componentPluginData.replacementkey;
            plugin.replacements[replacementkey] = value;
        } else if (componentPluginData.prop === 'dataAttributes') {
            const attributename: string = componentPluginData.attributename;
            let attr;
            if (plugin.dataAttributes.length < 1) {
                plugin.dataAttributes = [];
            } else {
                attr = plugin.dataAttributes.find(dataAttr => dataAttr.name === attributename)
            }
            if (attr === undefined) {
                if (isChecked === true) {
                    plugin.dataAttributes.push({
                        name: attributename,
                        value: value
                    });
                } else if (value === 'true') {
                    // if unchecked boolean
                    plugin.dataAttributes.push({
                        name: attributename,
                        value: 'false'
                    });
                }
            } else if (isChecked === true) {
                attr.value = value;
            } else {
                if (value === 'true') {
                    // if unchecked boolean
                    attr.value = 'false';
                } else {
                    // delete if not boolean
                    const index: number = plugin.dataAttributes.findIndex(function (attrib) { return attrib.name === attributename });
                    plugin.dataAttributes.splice(index, 1);
                }
            }
        } else {
            if (isChecked === true) {
                plugin[componentPluginData.prop] = value;
            } else {
                if (value === 'true') {
                    // if unchecked boolean
                    plugin[componentPluginData.prop] = 'false';
                } else {
                    plugin[componentPluginData.prop] = value;
                }
            }
        }
    }

    private updateIcheckPlugin(plugin, componentPluginData, value) {
        const noColorThemes = ['futurico', 'polaris'];
        const replacementkey: string = componentPluginData.replacementkey;
        plugin.replacements[replacementkey] = value;
        if (replacementkey === 'theme') {
            if (noColorThemes.indexOf(value) !== -1) {
                delete plugin.replacements['color'];
                $('select[name="icheck-color-data"]').closest('.form-group').hide(200);
                $('#icheck-nocolor-msg').show(200);
            } else {
                plugin.replacements['color'] = $('select[name="icheck-color-data"]').val();
                $('select[name="icheck-color-data"]').closest('.form-group').show(200);
                $('#icheck-nocolor-msg').hide(200);
            }
            if (value === 'flat' || value === 'minimal' || value === 'square') {
                plugin.jsConfig = 'default';
            } else if (value === 'futurico' || value === 'polaris') {
                plugin.jsConfig = 'theme';
            } else if (value === 'line') {
                plugin.jsConfig = 'line';
                delete plugin.replacements['theme'];
            }
        }
    }

    /* Sections
    -------------------------------------------------- */

    private addSection(componentType, component, sectionId = '') {
        if (sectionId.length === 0) {
            sectionId = 's-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }

        // create section
        const section: Section = {
            id: sectionId,
            componentType: componentType,
            component: component
        }
        // add section to formSections
        this.formSections.push(section);

        // reset form min height after 1st component
        /* if (this.formSections.length > 0) {
            console.log('remove min-height');
            $('#fg-form-wrapper, #' + this.ui.form.id).css('min-height', '');
        } */

        return section;
    }

    private duplicateSection(originSectionId, newSectionId) {
        const originSection = this.formSections.find(section => section.id === originSectionId);
        let newComponent = $.extend(true, {}, originSection.component);
        newComponent.label += '-copy';
        newComponent.name += ' Copy';
        this.addSection(originSection.componentType, newComponent, newSectionId);
    }

    private reloadSection(sectionId) {
        const sct = this.formSections.find(section => section.id === sectionId);
        this.renderSection(sct.componentType, sct, '#' + sct.id, true);
    }

    private renderSection(componentType, section, target, active = false, callback = () => { return; }) {
        // clone section.component to avoid changing section.component attr
        const comp = Object.assign({}, section.component);

        if (comp.attr !== undefined) {
            comp.attr = Utilities.attrArrayToString(section.component.attr);
        }
        $.ajax({
            url: `ajax/${componentType}.php`,
            type: 'POST',
            data: { 'data': JSON.stringify(comp) }
        }).done((data) => {
            const $newSection = $('<section />').attr('id', section.id).addClass(this.ui.sectionClass);
            if (active === true) {
                $newSection.addClass(this.ui.sectionActiveClass);
            }
            // section width according to component width
            if (comp.width !== undefined) {
                const compWidth: string = comp.width;
                const widths = {
                    '100%': 'w-100',
                    '66%': 'w-66',
                    '50%': 'w-50',
                    '33%': 'w-33'
                }
                $newSection.addClass(widths[compWidth]);
            }
            const $sectionMove = $(`<div class="section-tools d-flex"><button type="button" class="btn btn-link text-decoration-none btn-block h-100 btn-drag btn-drag-move handle" data-bs-toggle="tooltip" title="Drag to move the element. Hold the ctrl key before dragging to duplicate it."><i class="icon-move"></i></button><button type="button" class="btn btn-link text-decoration-none btn-block h-100 d-none btn-drag btn-drag-copy handle"><i class="icon-copy"></i></button></div>`);
            const $sectionDelete = $(`<div class="section-tools d-flex"><button type="button" class="btn btn-link text-decoration-none btn-block h-100 btn-delete" data-bs-toggle="popover"><i class="icon-delete text-danger"></i></button></div>`);
            $newSection.append($sectionMove);
            $newSection.append(data);
            $newSection.append($sectionDelete);
            $(target).replaceWith($newSection);
            // refresh form components sortable
            sortable('#' + this.ui.form.id);
            $newSection.find('[data-bs-toggle="tooltip"]').tooltip();
            $newSection.find('[data-bs-toggle=popover]').popover({
                html: true,
                container: $('#' + this.ui.form.id),
                content: () => {
                    return $(`<div>
                        <h5>Delete?</h5>
                        <div class="btn-group" role="group" aria-label="Delete?" data-section-id="` + section.id + `">
                            <button type="button" class="btn btn-sm btn-secondary delete-cancel">No</button>
                            <button type="button" class="btn btn-sm btn-danger delete-confirm">Yes</button>
                        </div>
                    </div>`);
                }
            });
            let _nc = new NiceCheck('#' + section.id);

            // callback
            callback();

        }).fail(function (_data, _statut, error) {
            console.log(error);
        });
    }

    /* Button (from button group only)
    -------------------------------------------------- */

    private updateButtonFromButtongroup(sectionId, buttonIndex, buttonProp, buttonValue) {
        const component = this.formSections.find(section => section.id === sectionId).component;
        const bt = component.buttons.find(btn => btn.index === buttonIndex);
        bt[buttonProp] = buttonValue;
        // merge clazz with attributes for PHP Form Builder
        if (buttonProp === 'clazz') {
            this.updateComponentItemAttr(bt, 'class', buttonValue);
        }

        // reload the form field
        this.reloadSection(sectionId);
    }

    /* Checkbox
    -------------------------------------------------- */

    private addCheckbox(sectionId) {
        const checkboxes = this.formSections.find(section => section.id === sectionId).component.checkboxes;
        const newCheckboxIndex: number = checkboxes.length + 1;
        const newCheckbox: Checkbox = {
            index: newCheckboxIndex,
            text: 'Label ' + newCheckboxIndex,
            value: this.componentSettings.getUniqueValue('value-' + newCheckboxIndex, checkboxes),
            disabled: false,
            attr: [
                {
                    name: 'data-color',
                    value: ''
                },
                {
                    name: 'data-icon',
                    value: ''
                },
                {
                    name: 'data-toggle',
                    value: 'false'
                },
                {
                    name: 'data-on-label',
                    value: ''
                },
                {
                    name: 'data-off-label',
                    value: ''
                },
                {
                    name: 'data-on-icon',
                    value: ''
                },
                {
                    name: 'data-off-icon',
                    value: ''
                },
                {
                    name: 'data-on-color',
                    value: ''
                },
                {
                    name: 'data-off-color',
                    value: ''
                }
            ]
        }
        checkboxes.push(newCheckbox);

        // add the new checkbox to the component checkbox tab
        const $fieldset = this.componentSettings.buildCheckbox(newCheckbox);
        $('#tab-items').find('#items-wrapper').append($fieldset);
        let _nc = new NiceCheck(`#items-wrapper fieldset[data-index="${newCheckboxIndex}"]`);

        // set delete btn popover
        const popoverDeleteData = {
            sectionId: sectionId,
            componentType: 'checkbox',
            itemIndex: newCheckboxIndex
        };
        this.componentSettings.enableDeletePopover(popoverDeleteData, `#items-wrapper fieldset[data-index="${newCheckboxIndex}"]`);

        // reload the form field
        this.reloadSection(sectionId);
    }

    private deleteCheckbox(sectionId, checkboxIndex) {
        const checkboxes = this.formSections.find(section => section.id === sectionId).component.checkboxes;
        checkboxes.splice(checkboxIndex - 1, 1);

        // reindex checkboxes
        this.reindexTabItems(checkboxes);

        // rebuild the checkboxes tab
        const $div = $('<div />');
        checkboxes.forEach(chk => {
            const $fieldset = this.componentSettings.buildCheckbox(chk);
            $div.append($fieldset);
        });
        $('#items-wrapper').html($div.html());
        let _nc = new NiceCheck('#tab-items');

        // enable popover (dom has been rebuilt)
        checkboxes.forEach(chk => {
            // set delete btn popover
            const popoverDeleteData = {
                sectionId: sectionId,
                componentType: 'checkbox',
                itemIndex: chk.index
            };
            this.componentSettings.enableDeletePopover(popoverDeleteData, `#tab-items fieldset[data-index="${chk.index}"]`);
        });

        // reload the form field
        this.reloadSection(sectionId);
    }

    private updateCheckbox(sectionId, checkboxIndex, checkboxProp, checkboxValue) {
        const component = this.formSections.find(section => section.id === sectionId).component;
        const checkboxes = component.checkboxes;
        checkboxes[checkboxIndex - 1][checkboxProp] = checkboxValue;

        if (checkboxProp === 'value') {
            this.updateComponentDefaultValue(checkboxes, component.value);
        }

        // reload the form field
        this.reloadSection(sectionId);
    }

    /* Radio button
    -------------------------------------------------- */

    private addRadioButton(sectionId) {
        const radioButtons = this.formSections.find(section => section.id === sectionId).component.radioButtons;
        const newRadioIndex: number = radioButtons.length + 1;
        const newRadioButton: RadioButton = {
            index: newRadioIndex,
            text: 'Label ' + newRadioIndex,
            value: this.componentSettings.getUniqueValue('value-' + newRadioIndex, radioButtons),
            disabled: false,
            attr: [
                {
                    name: 'data-color',
                    value: ''
                },
                {
                    name: 'data-icon',
                    value: ''
                },
                {
                    name: 'data-toggle',
                    value: 'false'
                },
                {
                    name: 'data-on-label',
                    value: ''
                },
                {
                    name: 'data-off-label',
                    value: ''
                },
                {
                    name: 'data-on-icon',
                    value: ''
                },
                {
                    name: 'data-off-icon',
                    value: ''
                },
                {
                    name: 'data-on-color',
                    value: ''
                },
                {
                    name: 'data-off-color',
                    value: ''
                }
            ]
        }
        radioButtons.push(newRadioButton);

        // add the new radio button to the component radio tab
        const $fieldset = this.componentSettings.buildRadioButton(newRadioButton);
        $('#tab-items').find('#items-wrapper').append($fieldset);
        let _nc = new NiceCheck(`#items-wrapper fieldset[data-index="${newRadioIndex}"]`);

        // set delete btn popover
        const popoverDeleteData = {
            sectionId: sectionId,
            componentType: 'radio',
            itemIndex: newRadioIndex
        };
        this.componentSettings.enableDeletePopover(popoverDeleteData, `#items-wrapper fieldset[data-index="${newRadioIndex}"]`);

        // reload the form field
        this.reloadSection(sectionId);
    }

    private deleteRadioButton(sectionId, radioIndex) {
        const radioButtons = this.formSections.find(section => section.id === sectionId).component.radioButtons;
        radioButtons.splice(radioIndex - 1, 1);

        // reindex radio
        this.reindexTabItems(radioButtons);

        // rebuild the radio tab
        const $div = $('<div />');
        radioButtons.forEach(rad => {
            const $fieldset = this.componentSettings.buildRadioButton(rad);
            $div.append($fieldset);
        });
        $('#items-wrapper').html($div.html());
        let _nc = new NiceCheck('#tab-items');

        // enable popover (dom has been rebuilt)
        radioButtons.forEach(rad => {
            // set delete btn popover
            const popoverDeleteData = {
                sectionId: sectionId,
                componentType: 'radio',
                itemIndex: rad.index
            };
            this.componentSettings.enableDeletePopover(popoverDeleteData, `#tab-items fieldset[data-index="${rad.index}"]`);
        });

        // reload the form field
        this.reloadSection(sectionId);
    }

    private updateRadioButton(sectionId, radioIndex, radioProp, radioValue) {
        const component = this.formSections.find(section => section.id === sectionId).component;
        const radioButtons = component.radioButtons;
        radioButtons[radioIndex - 1][radioProp] = radioValue;

        if (radioProp === 'value') {
            this.updateComponentDefaultValue(radioButtons, component.value);
        }

        // reload the form field
        this.reloadSection(sectionId);
    }

    /* Select option
    -------------------------------------------------- */

    private addSelectOption(sectionId) {
        const selectOptions = this.formSections.find(section => section.id === sectionId).component.selectOptions;
        const newOptionIndex: number = selectOptions.length + 1;
        const newOption: SelectOption = {
            index: newOptionIndex,
            text: 'Option ' + newOptionIndex,
            value: this.componentSettings.getUniqueValue('value-' + newOptionIndex, selectOptions),
            groupname: ''
        }
        selectOptions.push(newOption);

        // add the new option to the component options tab
        const $fieldset = this.componentSettings.buildSelectOption(newOption);
        $('#tab-items').find('#items-wrapper').append($fieldset);

        // set delete btn popover
        const popoverDeleteData = {
            sectionId: sectionId,
            componentType: 'select',
            itemIndex: newOptionIndex
        };
        this.componentSettings.enableDeletePopover(popoverDeleteData, `#items-wrapper fieldset[data-index="${newOptionIndex}"]`);

        // reload the form field
        this.reloadSection(sectionId);
    }

    private deleteSelectOption(sectionId, optionIndex) {
        const selectOptions = this.formSections.find(section => section.id === sectionId).component.selectOptions;
        selectOptions.splice(optionIndex - 1, 1);

        // reindex options
        this.reindexTabItems(selectOptions);

        // rebuild the options tab
        const $div = $('<div />');
        selectOptions.forEach(opt => {
            const $fieldset = this.componentSettings.buildSelectOption(opt);
            $div.append($fieldset);
        });
        $('#items-wrapper').html($div.html());

        // enable popover (dom has been rebuilt)
        selectOptions.forEach(opt => {
            // set delete btn popover
            const popoverDeleteData = {
                sectionId: sectionId,
                componentType: 'select',
                itemIndex: opt.index
            };
            this.componentSettings.enableDeletePopover(popoverDeleteData, `#tab-items fieldset[data-index="${opt.index}"]`);
        });

        // reload the form field
        this.reloadSection(sectionId);
    }

    private updateSelectOption(sectionId, optionIndex, optionProp, optionValue) {
        const component = this.formSections.find(section => section.id === sectionId).component;
        const selectOptions = component.selectOptions;
        selectOptions[optionIndex - 1][optionProp] = optionValue;
        if (optionProp === 'value') {
            this.updateComponentDefaultValue(selectOptions, component.value);
        }

        // reload the form field
        this.reloadSection(sectionId);
    }

    /* Utilities
    -------------------------------------------------- */

    private hasUnsavedChanges(trueFalse: boolean) {
        if (trueFalse === true) {
            $('body').addClass('has-unsaved-changes');
        } else {
            $('body').removeClass('has-unsaved-changes');
        }
    }

    private reindexSections() {
        const $sections = $('#' + this.ui.form.id + '>section');
        const reindexedSections: Array<Section> = [this.formSections[0]];
        let sectId: any;
        let sectObject: Section;

        for (let sect of $sections) {
            sectId = $(sect).prop('id');
            sectObject = this.formSections.find(s => s.id === sectId);
            if (sectObject !== undefined) {
                reindexedSections.push(sectObject);
            }
        }

        this.formSections = reindexedSections;
    }

    /**
     * reindex select options | checkboxes | radio after deletion
     * @param tabId
     * @param objectArray
     */
    private reindexTabItems(objectArray) {
        let index = 1;
        objectArray.forEach(item => {
            item.index = index;
            index++;
        });
    }

    private resetComponentsIndexes() {
        this.componentsIndex = {
            'button': 0,
            'buttongroup': 0,
            'checkbox': 0,
            'dependent': 0,
            'dependentend': 0,
            'fileuploader': 0,
            'hcaptcha': 0,
            'html': 0,
            'input': 0,
            'paragraph': 0,
            'radio': 0,
            'recaptcha': 0,
            'select': 0,
            'textarea': 0,
            'title': 0
        }
    }

    private resizePreviewIframe($iframe) {

        setTimeout(() => {
            const h = $('#preview-modal').find('.modal-body').height();
            $iframe.attr('height', h);
        }, 400);
    }

    private startForm() {
        let formAttributes = this.ui.form.attr;
        if (this.ui.form.layout === 'horizontal') {
            formAttributes = Utilities.addClass('form-horizontal', formAttributes);
        } else if (this.ui.form.layout === 'inline') {
            formAttributes = Utilities.addClass('form-inline', formAttributes);
        }
        formAttributes = Utilities.addClass('d-flex flex-wrap', formAttributes);

        const formHtml: string = `<form id="${this.ui.form.id}" ${formAttributes}></form>`;
        $('#fg-form-wrapper').html(formHtml);
    }

    private testUploaderSettings(comp, attr) {
        let imageAttributes = ['thumbnails', 'editor', 'widthImg', 'heightImg', 'crop'];
        if (comp.xml !== 'image-upload' && imageAttributes.indexOf(attr) !== -1) {
            alert('Be sure to select "Image Upload" as the uploader first, otherwise the "Image upload settings" will be ignored.');
            return false;
        }
        return true;
    }

    private testFormConsistency() {
        const errors = [];
        if (this.formSections.length === 1) {
            // 1 component = form itself
            const err: ErrorMessage = {
                title: 'Empty form error',
                text: 'You\'ve got to add some fields first.'
            }
            errors.push(err);
        } else {
            let allPlugins = [];
            this.formSections.forEach(section => {
                if (section.component.componentType === 'hcaptcha' || section.component.componentType === 'recaptcha') {
                    allPlugins.push(section.component.componentType);
                }
                if (section.component.plugins !== undefined) {
                    // register the section plugins in allPlugins
                    section.component.plugins.forEach(pl => {
                        allPlugins.push(pl.pluginName);
                    });
                    // test Bootstrap Select + framework
                    if (section.component.plugins.find(pl => pl.pluginName === 'bootstrap-select') !== undefined) {
                        const bootstrapFrameworks = ['bs4', 'bs5', 'bs4-material'];
                        if (bootstrapFrameworks.indexOf(this.userForm.framework) === -1) {
                            const err: ErrorMessage = {
                                title: 'Bootstrap Select plugin with ' + this.userForm.framework,
                                text: 'The <em>Bootstrap Select</em> plugin requires Bootstrap.<br>Open the <em>Main Settings</em> and change the form framework or remove the Bootstrap plugin from your form.'
                            }
                            errors.push(err);
                        }
                    }
                }
            });
            // check if hcaptcha + recaptcha
            if (allPlugins.indexOf('hcaptcha') !== -1 && allPlugins.indexOf('recaptcha') !== -1) {
                const err: ErrorMessage = {
                    title: 'Hcaptcha plugin with Recaptcha',
                    text: 'The <em>Hcaptcha</em> plugin cannot be used simultaneously with <em>Recaptcha</em>'
                }
                errors.push(err);
            }
            // check if hcaptcha + recaptcha
            if (allPlugins.indexOf('modal') !== -1 && allPlugins.indexOf('popover') !== -1) {
                const err: ErrorMessage = {
                    title: 'Modal plugin with Popover',
                    text: 'The <em>Modal</em> plugin cannot be used simultaneously with <em>Popover</em>'
                }
                errors.push(err);
            }
            // check dependent start / end matching
            let dependentStartsCount: number = 0;
            let dependentEndCount: number = 0;
            this.formSections.forEach(section => {
                if (section.componentType === 'dependent') {
                    if (section.component.name.length === 0) {
                        const err: ErrorMessage = {
                            title: 'Dependent fields error',
                            text: 'A dependent field has no parent field.'
                        }
                        errors.push(err);
                    }
                    dependentStartsCount++;
                } else if (section.componentType === 'dependentend') {
                    dependentEndCount++;
                }
            });

            if (dependentStartsCount - dependentEndCount > 0) {
                const err: ErrorMessage = {
                    title: 'Dependent fields error',
                    text: 'A dependent field starts but is not closed.'
                }
                errors.push(err);
            } else if (dependentStartsCount - dependentEndCount < 0) {
                const err: ErrorMessage = {
                    title: 'Dependent fields error',
                    text: 'A dependent field ends but is not opened.'
                }
                errors.push(err);
            }
        }

        return errors;
    }

    private throwErrors(formErrors: Array<ErrorMessage>) {
        formErrors.forEach(error => {
            const $errorTitle = $('<h5 />').addClass('fw-light').text(error.title);
            const $errorText = $('<p />').addClass('alert alert-danger').html(error.text);
            $('#errors-modal').find('.modal-body').append($errorTitle).append($errorText);
        });
        $('#errors-modal').modal('show');
        $('#errors-modal').on('hidden.bs.modal', function () {
            $('#errors-modal').find('.modal-body').html('');
        });
    }
}
